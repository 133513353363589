import * as React from "react"
import styled from 'styled-components'

import media from '../styles/media'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Nav from "../components/Nav"

const FourDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 90px;
    line-height: 80px;
    height: fit-content;
  }
  p {
    font-size: 18px;
    line-height: 24px;
    max-width: 60%;
    margin: 20px 20%;
  }

  ${media.laptop`
    span {
      font-size: 120px;
      line-height: 80px;
      padding-bottom: 20px;
    }
    p {
      font-size: 22px;
      line-height: 25px;
      max-width: auto;
      margin: 20px auto;
    }
  `}
`

const NotFoundPage = () => (
  <Layout>
    <FourDiv>
      <Seo title="404: Not found" />
      <span className="head">404</span>
      <p>Sorry, we can’t seem to find the page that you’re looking for.</p>
    </FourDiv>
    <Nav />
  </Layout>
)

export default NotFoundPage
